export function bindResultsPage() {
  const buttons = [
    "btnTotal",
    "btnPerKW",
    "btnPerKWh"
  ];

  // Function to set a cookie
  function setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  // Function to get a cookie
  function getCookie(name) {
    const cname = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cname) === 0) {
        return c.substring(cname.length, c.length);
      }
    }
    return "";
  }
  
  
  // buttons.forEach(id => {
  //   let element = document.getElementById(id);
  //   if(element) {
  //     element.addEventListener('click', function() {
  //       setActiveButton(this);
  //       document.querySelectorAll(buttons[id][0]).forEach(el => el.classList.remove('d-none'));
  //       buttons[id][1].forEach(selector => {
  //         document.querySelectorAll(selector).forEach(el => el.classList.add('d-none'));
  //       });
  //     });
  //   }
  // });

  function setActiveButton(button) {
    document.querySelectorAll('.btn').forEach(btn => btn.classList.remove('active'));
    button.classList.add('active');
    setCookie("selectedButton", button.id, 7); // Store the selected button in a cookie for 7 days
  }
  
  // Restore the stored preference
  const storedButtonId = getCookie("selectedButton");
  if (storedButtonId && buttons[storedButtonId]) {
    let button = document.getElementById(storedButtonId);
    if(button) {
      document.getElementById(storedButtonId).click();
    }
  } else {
    console.log("no stored pref or not found")
  }
}